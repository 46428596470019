import React, { useState } from "react";
import "../../App.scss";
import "./viewImageScreen.scss";
import { sendArrow } from "../../assets";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../../theme/Theme.context";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import axios from "axios";
import {
  getTyreDetailInitiated,
  getTyreDetailSucceed,
  getTyreDetailFailed,
} from "../../statemanagment/index";
import { Button, ErrorScreen, Spinner } from "../../components/index";
import { dataURLtoFile } from "../../utils/index";

const ViewImageScreen = () => {
  const { getTyreDetailsError, getTyreDetailsInitiated } = useSelector(
    (state) => state.tyreSnapApiReducer,
    shallowEqual
  );
  const navigate = useNavigate();
  const imageValue = sessionStorage.getItem("imageValue");
  const imageUrl = sessionStorage.getItem("imageUrl");
  const fileName = sessionStorage.getItem("fileName");
  const imageType = sessionStorage.getItem("imageType");

  const { theme } = useTheme();
  const dispatch = useDispatch();

  const [accessToken, setAccessToken] = useState("");
  const [getTokenError, setGetTokenError] = useState("");

  const getToken = () => {
    const API_URL = process.env.REACT_APP_GET_TOKEN_API;
    const data = {
      grant_type: "client_credentials",
    };

    const auth = {
      username: process.env.REACT_APP_CLIENT_ID,
      password: process.env.REACT_APP_CLIENT_SECRET,
    };

    const options = {
      method: "post",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: JSON.stringify(data),
      auth: auth,
      url: API_URL,
    };

    axios(options)
      .then((response) => {
        setAccessToken(response.data.access_token);
        setGetTokenError("");
      })
      .catch((error) => {
        console.log(error);
        setGetTokenError(error.message);
        setAccessToken("");
      });
  };

  const sendImage = async () => {
    if (accessToken) {
      try {
        const formData = new FormData();
        const file = dataURLtoFile(imageValue, fileName);
        formData.append("tire_image", file);
        formData.append("requester_name", process.env.REACT_APP_REQUESTER_NAME);
        formData.append(
          "unique_request_id",
          process.env.REACT_APP_UNIQUE_REQUEST_ID
        );
        dispatch(getTyreDetailInitiated());
        const response = await axios({
          method: "post",
          url: `${process.env.REACT_APP_TIRESNAP_ENDPOINT}tire-size-recognition/v1/score`,
          data: formData,
          headers: {
            Authorization: `Bearer ${accessToken}`,
            apikey: process.env.REACT_APP_API_KEY,
          },
        });

        if (response?.data?.result?.car_models_matches !== 0) {
          dispatch(getTyreDetailSucceed(response.data.result));
          navigate(`/${theme}/resultScreen`);
        } else {
          dispatch(getTyreDetailFailed({ message: "no match found" }));
        }
      } catch (error) {
        console.log("err" + error);
        dispatch(getTyreDetailFailed({ message: "Network error !" }));
      }
    }
  };

  if (Object.keys(getTyreDetailsError).length !== 0 || getTokenError !== "") {
    return (
      <ErrorScreen
        errorMessage={
          getTokenError ? getTokenError : getTyreDetailsError.message
        }
        onRetry={() => {
          if (getTokenError) {
            getToken();
          } else {
            dispatch(getTyreDetailFailed({}));
            navigate(`/${theme}/homeScreen`);
          }
        }}
      />
    );
  }

  if (getTyreDetailsInitiated) {
    return <Spinner show={true} />;
  }

  return (
    <div className={"view-image-screen"}>
      <div className={imageType === "capture" && "image-container-capture"}>
        <img
          className={
            imageType === "capture"
              ? "display-img-captutre"
              : "display-img-upoload"
          }
          src={imageUrl}
          alt="tyre"
        />
      </div>
      <div
        className={
          imageType === "capture"
            ? "button-container-capture"
            : "button-container-upload"
        }
      >
        <Button
          buttonStyle="secondary-button"
          onClick={sendImage}
          icon={sendArrow}
          buttonText="Send Image"
        />
        <div
          className="italian h2 underline-text"
          onClick={() => navigate(`/${theme}/cameraScreen`)}
        >
          Retake Image
        </div>
      </div>
    </div>
  );
};

export default ViewImageScreen;
