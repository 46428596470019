import React, { useRef, useState, useEffect } from "react";
import { Camera } from "react-camera-pro";
import { useNavigate } from "react-router-dom";
import {
  close,
  cameraButton,
  flipCamera,
  galleryIcon,
  cameraGuidance,
} from "../../assets";
import { useTheme } from "../../theme/Theme.context";
import "./cameraScreen.scss";
import { Spinner, ErrorScreen } from "../../components/index";
import imageCompression from "browser-image-compression";
import { toBase64, dataURLtoFile } from "../../utils/index";

const CameraScreen = () => {
  const camera = useRef(null);

  const interval = useRef(null);
  const { theme } = useTheme();
  const navigate = useNavigate();
  const [cameraLoaded, setCameraLoaded] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [cameraFacing, setCameraFacing] = useState("environment");

  useEffect(() => {
    if (!interval.current) {
      interval.current = setInterval(() => {
        const noOfCameras = camera.current?.getNumberOfCameras();
        if (noOfCameras && !cameraLoaded) {
          setCameraLoaded(true);
        }
      }, 500);
    }
  }, [camera.current]);

  useEffect(() => {
    if (cameraLoaded) {
      clearInterval(interval.current);
      interval.current = null;
    }
  }, [cameraLoaded]);

  const uploadImage = async (e) => {
    let file_size = e.target.files[0].size;
    let imgValue = e.target.files[0];
    const mb = file_size / 1000000;
    sessionStorage.setItem("imageSize", mb);
    sessionStorage.setItem("imageUrl", URL.createObjectURL(imgValue));
    sessionStorage.setItem("imageType", "upload");
    if (mb > 10) {
      setLoader(true);
      const options = {
        maxSizeMB: 10,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(imgValue, options);
        toBase64(compressedFile)
          .then((base64EncodedImg) => {
            sessionStorage.setItem("fileName", compressedFile.name);
            sessionStorage.setItem("imageValue", base64EncodedImg);
            setLoader(false);
            navigate(`/${theme}/viewImageScreen`);
          })
          .catch((error) => {
            setLoader(false);
            console.log(error);
            setError("Image resizing failed !!");
          });
      } catch (error) {
        console.log(error);
        setLoader(false);
        setError("Image resizing failed !!");
      }
    } else {
      toBase64(imgValue)
        .then((base64EncodedImg) => {
          sessionStorage.setItem("fileName", imgValue.name);
          sessionStorage.setItem("imageValue", base64EncodedImg);
          navigate(`/${theme}/viewImageScreen`);
        })
        .catch((error) => {
          setError("Image resizing failed !!");
          console.log(error);
        });
    }
  };
  if (loader) {
    return <Spinner show={loader} />;
  }
  if (error !== "") {
    return (
      <ErrorScreen
        errorMessage={error}
        onRetry={() => {
          setError("");
        }}
      />
    );
  }

  return (
    <div>
      <div className="spinner-div">
        <Spinner show={!cameraLoaded} />
      </div>
      <div className={"camera-div"}>
        <Camera ref={camera} aspectRatio="cover" facingMode={cameraFacing} />

        <div className="camera-header">
          <div className="camera-close-btn">
            <img
              className={"close"}
              src={close}
              alt={"back arrow"}
              onClick={() => navigate(`/${theme}/homeScreen`)}
            />
          </div>
          <div style={{ flex: "1 0 auto" }} className="h5 header-message">
            <div style={{ textAlign: "center" }}>
              Place the tyre within guided lines
            </div>
          </div>
        </div>
        <img
          className={"camera-guidance"}
          src={cameraGuidance}
          alt={"camera guidancce"}
          onClick={() => navigate(`/${theme}`)}
        />
        <div className="camera-footer">
          <label className={"choose-from-gallery-icon"}>
            <img
              className={"gallery-icon"}
              src={galleryIcon}
              alt={"back arrow"}
            />
            <input type="file" accept="image/*" onChange={uploadImage} />
          </label>
          <img
            className={"camera-button"}
            src={cameraButton}
            onClick={async () => {
              let imgValue = camera.current.takePhoto();

              const base64String = imgValue.substring(
                imgValue.indexOf(",") + 1
              );
              const imageType = imgValue.split(";")[0].split("/")[1];
              const fileName = "imageCaptured." + imageType;
              const bits = base64String.length * 6; // 567146
              const bytes = bits / 8;
              const mb = bytes / 1000000;
              sessionStorage.setItem("imageSize", mb);
              sessionStorage.setItem("imageUrl", imgValue);
              sessionStorage.setItem("imageType", "capture");

              const imageFile = dataURLtoFile(imgValue, fileName);

              if (mb > 10) {
                setLoader(true);
                const options = {
                  maxSizeMB: 10,
                  maxWidthOrHeight: 1920,
                  useWebWorker: true,
                };
                try {
                  const compressedFile = await imageCompression(
                    imageFile,
                    options
                  );
                  toBase64(compressedFile)
                    .then((base64EncodedImg) => {
                      sessionStorage.setItem("fileName", compressedFile.name);
                      sessionStorage.setItem("imageValue", base64EncodedImg);
                      setLoader(false);
                      navigate(`/${theme}/viewImageScreen`);
                    })
                    .catch((error) => {
                      setLoader(false);
                      console.log(error);
                      setError("Image resizing failed !!");
                    });
                } catch (error) {
                  console.log(error);
                  setLoader(false);
                  setError("Image resizing failed !!");
                }
              } else {
                sessionStorage.setItem("fileName", imageFile.name);
                sessionStorage.setItem("imageValue", imgValue);
                navigate(`/${theme}/viewImageScreen`);
              }
            }}
            alt={"camera button"}
          />
          <img
            className={"flip-icon"}
            src={flipCamera}
            alt={"flash icon"}
            onClick={() => {
              let cameraFacing = camera.current.switchCamera();
              setCameraFacing(cameraFacing);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default CameraScreen;
